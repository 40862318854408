<template>
  <div>
    <!-- {{ RESPONSABLES }} -->

    <b-table
      :items="items"
      :fields="print ? tableFieldsprint : tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      responsive
      bordered
      striped
      :busy="loading"
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #cell(organisation_attachment)="data">
        <div
          v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
        >
          <!-- <span>{{ data.item }}</span> -->
          {{
            RESPONSABLES.find((respo) => respo.id == data.item.member.id) &&
            RESPONSABLES.find((respo) => respo.id == data.item.member.id)
              .organisationRatachement
              ? RESPONSABLES.find((respo) => respo.id == data.item.member.id)
                  .organisationRatachement.libelle
              : "-"
          }}
        </div>

        <b-form-input
          disabled
          v-else
          v-model="organisation"
          placeholder="Organisation"
        ></b-form-input>
      </template>

      <template #cell(member)="data">
        <div
          v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
          class="text-center"
        >
          <TableResponsable
            v-if="!$route.path.includes('print')"
            :responsable="data.value"
          />
          <span v-else>
            {{ data.value.firstname }} {{ data.value.lastname }}
          </span>
        </div>
        <div v-else>
          <v-select
            appendToBody
            :options="responsables"
            label="text"
            :reduce="(respo) => respo.value"
            v-model="selectedUser"
            :value="data.value.id"
            placeholder="Membre"
            style="padding-right: 0 !important"
            :class="{
              'is-invalid form-control p-0':
                $v.selectedUser.$error && $v.selectedUser.$dirty,
            }"
          >
            <template #no-options> Liste vide </template>
          </v-select>
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.selectedUser.$dirty"
          >
            {{ !$v.selectedUser.required ? "Champ obligatoire" : "" }}
          </span>
        </div>
      </template>

      <template #cell(actions)="data">
        <table-actions
          v-if="!print"
          :actions="actions"
          :editMode="data.item.hasOwnProperty('editMode')"
          @confirmItem="confirmItem(data.item.id, data.item)"
          @cancelItem="cancelItem"
          @deleteItem="$emit('deleteItem', data.item.member.id)"
        ></table-actions>
      </template>
    </b-table>
    <b-pagination
      v-if="!print"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import { mapGetters } from "vuex";
import TableResponsable from "@/components/custom/TableResponsable.vue";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { USER_URL, MEMBER_URL } from "@/helpers/constURL";

export default {
  components: { TableActions, TableResponsable },
  props: {
    members: Object,
    items: Array,
    print: Boolean,
  },
  data: () => ({
    actions: ["delete"],
    selectedUser: "",
    sortBy: "",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    tableFields: [
      { key: "member", label: "Membre", sortable: true },
      {
        key: "organisation_attachment",
        label: "Organisation de rattachement",
        sortable: true,
        thStyle: { width: "400px !important", maxWidth: "500px !important" },
      },
      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },

        sortable: false,
      },
    ],
    tableFieldsprint: [
      { key: "member", label: "Membre", sortable: true },
      {
        key: "organisation_attachment",
        label: "Organisation de rattachement",
        sortable: true,
      },
    ],
    tableItems: [
      {
        actions: ["confirm", "edit"],
      },
    ],
    editedItem: null,
    newMember: {
      member: "",
      organisation_attachment: "",
    },
    loading: true,
    organisation: null,
  }),

  validations: {
    selectedUser: {
      required,
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables").then(() => {
      this.loading = false;
    });
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("teams/fetchAllTeams");
  },

  computed: {
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("comite", ["COMITES"]),
    ...mapGetters("teams", ["TEAMS"]),

    rows() {
      // return this.members.members ? this.members.members.length : 0;
      return this.items.length;
    },

    responsables() {
      return this.RESPONSABLES.filter(
        (responsable) =>
          !this.items.find((item) =>
            item.member ? item.member.id == responsable.id : false
          ) && responsable.organisationRatachement
      ).map((responsable) => ({
        value: `/api/${USER_URL}/${responsable.id}`,
        text: responsable.firstname + " " + responsable.lastname,
        id: responsable.id,
      }));
    },

    organisations() {
      return this.ORGANISATIONS.map((organisation) => ({
        value: organisation["@id"],
        text: organisation.libelle,
      }));
    },
    
    comites() {
      return this.COMITES.map((comite) => ({
        value: comite["@id"],
        text: comite.members.firstname + "" + comite.members.lastname,
      }));
    },
    membres() {
      var arr = this.TEAMS.map((team) => ({
        value: `/api/${MEMBER_URL}/${team.members[0].id}`,
        text: team.members.firstname + "" + team.members.lastname,
      }));
      return arr;
    },
  },
  methods: {
    confirmItem: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var confirm = !this.selectedUser == "";
        if (confirm) {
          var data = this.members.members.map((data) => data["@id"]);
          data.push(this.selectedUser);
          var contributer = {
            id: this.$route.params.id,
            members: data,
          };
          this.$store.dispatch("comite/updateComite", contributer).then(() => {
            Swal.fire("Le membre est bien ajouté  !", "", "success");
            this.$store.dispatch("comite/fetchMembers", this.$route.params.id);
            this.selectedUser = null;
            this.organisation = null;
          });
        }
      }
    },
    cancelItem: function () {
      if (this.selectedUser == "") {
        this.$emit("cancelItem");
      } else {
        this.selectedUser = "";
      }
    },
  },
  watch: {
    selectedUser() {
      if (this.selectedUser) {
        window.console.log(this.selectedUser);
        window.console.log(
          (this.organisation = this.RESPONSABLES.find(
            (responsable) =>
              `/api/${USER_URL}/${responsable.id}` == this.selectedUser
          ).organisationRatachement)
        );
        var organisation = this.RESPONSABLES.find(
          (responsable) =>
            `/api/${USER_URL}/${responsable.id}` == this.selectedUser
        ).organisationRatachement;
        this.organisation = organisation.libelle;
      } else this.organisation = null;
    },
  },
};
</script>

<style></style>
